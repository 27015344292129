import React from 'react'
import { Hero } from '../../components/Hero'
import Layout from '../../components/Layout'
import CTASection from '../../components/CTASection'
import { useStaticQuery, graphql } from 'gatsby'
import { Feature } from '../../components/Feature'
import { ReadNext } from '../../components/ReadNext'
import flowIcon from '../../assets/images/icons/flow.svg'
import codeIcon from '../../assets/images/icons/code.svg'
import { codeIntro } from './code'
import Block from '../../components/Block'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'

export const flowIntro = (
  <>
    Best software teams deliver value continuously. Use meaningful agile metrics
    to diagnose productivity issues, remove blockers and increase velocity.
  </>
)

const FlowPage = () => {
  const flowImages = useStaticQuery(graphql`
    query {
      flow1: file(relativePath: { eq: "images/flow-1@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      flow2: file(relativePath: { eq: "images/flow-2@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      flow3: file(relativePath: { eq: "images/flow-3@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Flow insights"
      description="Best software teams deliver value continuously. Use meaningful agile metrics
  to diagnose productivity issues, remove blockers and increase velocity."
    >
      <Hero>
        <div className="icon">
          <img src={flowIcon} aria-hidden="true" />
        </div>
        <h1>Flow insights</h1>
        <div className="hero-description">
          <p className="large_text">{flowIntro}</p>
        </div>
        <MainDemoRequestCTA />
      </Hero>
      <Block>
        <Feature
          type="feature feature-grid-right"
          heading="Flow metrics"
          image={flowImages.flow1.childImageSharp.gatsbyImageData}
          imageAlt="Screenshot of Swarmia Insights showing Jira stories in progress and issue cycle time"
        >
          <p>✅ Measure and limit work in progress</p>
          <p>✅ Don&#39;t let scope creep become a habit</p>
          <p>
            ✅ Follow work volume and cycle time for each team and for the
            organization as a whole
          </p>
        </Feature>
        <Feature
          type="feature feature-grid-left"
          heading="Holistic view of team output and work patterns"
          image={flowImages.flow2.childImageSharp.gatsbyImageData}
          imageAlt="Screenshot of Swarmia's high-level Work Log showing completed Jira stories and issues"
        >
          <p>✅ Identify patterns that slow the team down</p>
          <p>
            ✅ Investigate individual projects to improve planning and
            retrospectives
          </p>
          <p>✅ See a year&#39;s worth of work at a glance</p>
        </Feature>
        <Feature
          type="feature feature-grid-right"
          heading="Continuous improvement"
          image={flowImages.flow3.childImageSharp.gatsbyImageData}
          imageAlt="Screenshot of Swarmia's automated working agreement on closing bugs under 3 days"
        >
          <p>
            ✅ Create targets for cycle time, review time, track WIP limits over
            time{' '}
          </p>
          <p>✅ Get actionable alerts when losing focus on key initiatives</p>
          <p>
            ✅ Produce automated reports for retrospectives and planning
            meetings
          </p>
        </Feature>
      </Block>
      <ReadNext
        label="Explore next"
        heading="Code insights"
        icon={codeIcon}
        url="/product/code/"
      >
        <p className="large_text">{codeIntro}</p>
      </ReadNext>
      <CTASection>
        Unblock the flow. Join&nbsp;the&nbsp;1,000+ teams using Swarmia today.
      </CTASection>
    </Layout>
  )
}

export default FlowPage

import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import codeIcon from '../../assets/images/icons/code.svg'
import continuousIcon from '../../assets/images/icons/ways.svg'
import { Block } from '../../components/Block'
import CTASection from '../../components/CTASection'
import { Feature } from '../../components/Feature'
import { Hero } from '../../components/Hero'
import Layout from '../../components/Layout'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'
import { ReadNext } from '../../components/ReadNext'

export const codeIntro = (
  <>
    Get a clear view into your development pipeline. Understand what slows teams
    down, and improve code quality with actionable metrics.
  </>
)

const CodePage = () => {
  const codeImages = useStaticQuery(graphql`
    query {
      code1: file(relativePath: { eq: "images/code-1@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      code2: file(relativePath: { eq: "images/code-2@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      code3: file(relativePath: { eq: "images/code-3@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Code insights"
      description="Get a clear view into your development pipeline. Understand what slows teams
    down, and improve code quality with actionable metrics."
    >
      <Hero>
        <div className="icon">
          <img src={codeIcon} aria-hidden="true" />
        </div>
        <h1>Code insights</h1>
        <div className="hero-description">
          <p className="large_text">{codeIntro}</p>
        </div>
        <MainDemoRequestCTA />
      </Hero>
      <div className="features" id="features">
        <Block>
          <Feature
            type="feature feature-grid-right"
            heading="Actionable metrics"
            image={codeImages.code1.childImageSharp.gatsbyImageData}
            imageAlt="Screenshot of Swarmia Insights with GitHub pull requests in progress and Jira issue cycle time"
          >
            <p>✅ Throughput, cycle time breakdown for ongoing work.</p>
            <p>✅ CI/CD insights: flaky test analysis, deployment frequency</p>
            <p>✅ Team and organization level metrics, industry benchmarks</p>
            <p>✅ Aligned with DORA and SPACE metrics</p>
          </Feature>
          <Feature
            type="feature feature-grid-left"
            heading="End-to-end view into development pipeline"
            image={codeImages.code2.childImageSharp.gatsbyImageData}
            imageAlt="Screenshot of Swarmia's GitHub pull request view with open PRs waiting for review"
          >
            <p>
              ✅ Measure all steps of software delivery: coding, review, merge,
              and deployment time
            </p>
            <p>
              ✅ Identify stale, heated, and large pull requests before they
              become a problem
            </p>
            <p>✅ Improve collaboration within the team and between teams</p>
          </Feature>
          <Feature
            type="feature feature-grid-right"
            heading="Continuous improvement"
            image={codeImages.code3.childImageSharp.gatsbyImageData}
            imageAlt="Screenshot of Swarmia's automated working agreement on closing GitHub pull requests in under 4 days"
          >
            <p>✅ Measure your ways of working</p>
            <p>
              ✅ Adopt work in progress limits, improve code review habits,
              increase code quality, and more
            </p>
            <p>
              ✅ Get automated reminders to stay on track with your new habits
            </p>
          </Feature>
        </Block>
      </div>
      <ReadNext
        label="Explore next"
        heading="Working Agreements"
        icon={continuousIcon}
        url="/product/working-agreements/"
      >
        <p className="large_text">
          Make continuous improvement a habit by adopting practices followed by
          the industry&#39;s best performers. Automate feedback loops and
          measure progress.
        </p>
      </ReadNext>
      <CTASection>
        Get a clear view into your development pipeline. Join 1,000+ teams using
        Swarmia today.
      </CTASection>
    </Layout>
  )
}

export default CodePage
